<template>
  <article v-loading="loading">
    <RouterNav :name="name"></RouterNav>
    <div class="work-detail">
      <iframe ref="iframes" class="iframe" :src="iframeSrc" frameborder="0"></iframe>
    </div>
  </article>
</template>

<script>
import RouterNav from '@/components/RouterNav.vue'
import { getUUID } from '@/utils/local'

export default {
  name: 'WorkStudentReportIframe',
  components: {
    RouterNav
  },
  data() {
    return {
      loading: false,
      name: '',
      userId: '',
      itemId: '',
      flag: '',
      workName: '',
      clazzId: '',
      workStudentReportInfo: {},
      iframeSrc: ''
    }
  },
  created() {
    this.userId = this.$route.query.userId
    this.itemId = this.$route.query.itemId
    this.clazzId = this.$route.query.clazzId
    this.workName = this.$route.query.workName
    this.flag = Number(this.$route.query.flag)
    this.workStudentReportInfo = JSON.parse(sessionStorage.getItem('workStudentReport'))
    this.name = this.workStudentReportInfo.realName + '的作业报告'
    this.setIframeSrc()
  },
  methods: {
    setIframeSrc() {
      const sessionId = sessionStorage.getItem('sessionId')
      const udid = getUUID()
      const studentName = this.workStudentReportInfo.realName
      if (this.flag === 9) {
        this.iframeSrc = `${this.$urls.homeworkReport2wordSrc}?itemId=${this.itemId}&release=3.0.0&machine=web&authToken=${sessionId}&clazzId=${this.clazzId}&udid=${udid}&device=web&thirdTypeId=1&apps=2&userId=${this.userId}&title=${this.workName}&studentName=${studentName}`
      } else if(this.flag === 24) {
        this.iframeSrc = `${this.$urls.homeworkReport2textSrc}?itemId=${this.itemId}&clazzId=${this.clazzId}&userId=${this.userId}&udid=${udid}&apps=2&release=3.0.0&title=${this.workName}&thirdTypeId=1&studentName=${studentName}`
      } else {
        this.iframeSrc = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.work-detail {
  padding-left: 20px;
}

.iframe {
  width: 100%;
  height: calc(100vh - 140px);
}
</style>
